import React from 'react';
import './Footer.css';
import ContactForm from '../ContactForm';
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-topRow">
                <h1>Let's Talk</h1>
                <div className="contact-form">
                    <ContactForm />
                </div>
            </div>

            <div className='footer-bottomRow'>
                <div className='footer-section'>
                    {/* Address - Example linking to Google Maps */}
                    <div className="contact-info">
                        <p className='footerText'>
                            <a href="https://maps.google.com/?q=2801+University+Blvd,+Ste+200+Birmingham,+AL+35233" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                2801 University Blvd, Ste 200<br />Birmingham, AL 35233
                            </a>
                        </p>
                    </div>
                    {/* Telephone Numbers */}
                    <div>
                        <p className='footerText'>
                            <a href="tel:2059309200" style={{ textDecoration: 'none', color: 'inherit' }}>205.930.9200</a><br />
                            <a href="tel:8008008881" style={{ textDecoration: 'none', color: 'inherit' }}>800.800.8881</a>
                        </p>
                    </div>
                    {/* Social Links */}
                    <div className="social-links">
                        <p className='footerText'>
                            <a href="https://www.facebook.com/profile.php?id=61552866218826" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>Facebook</a><br />
                            <a href="https://www.linkedin.com/company/martin-results/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>LinkedIn</a><br />
                            <a href="https://www.instagram.com/martin.advertising/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>Instagram</a>
                        </p>
                    </div>
                    {/* Add Privacy Policy and Terms & Conditions Links */}
                    <div className='footer-links'>
                        <p className='footerText'>
                        <Link to="/termsandconditions" style={{ textDecoration: 'none', color: 'inherit' }}>Terms & Conditions</Link><br />
                        <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}>Privacy Policy</Link>

                        </p>
                    </div>
                    <div className='logoFooterDiv' />
                </div>

                <div className='smallScreenLogoFooterDiv' />

            </div>
        </footer>
    );
};

export default Footer;
