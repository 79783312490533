import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
    return (
        <div className="terms-container">
            <h1>Martin Advertising Website Terms and Conditions</h1>

            <section>
                <h2>Copyright</h2>
                <p>
                    The website HTML, text, images, audio, video, software or other content that is made available on this website or otherwise hosted by Martin Advertising are the property of someone - the author in the case of content produced elsewhere and reproduced here with permission, or Martin Advertising or its content suppliers. Before you use this content in some way, please take care to ensure that you have the relevant rights and permissions from the copyright holder.
                </p>
            </section>

            <section>
                <h2>Trademarks</h2>
                <p>
                    Any trademarks, logos, and service marks displayed on this website are the property of their owners, whether Martin Advertising or third parties. For example, Cadillac is a registered trademark of General Motors, LLC.
                </p>
            </section>

            <section>
                <h2>Privacy Policy</h2>
                <p>
                    Our collection and use of your personal information is governed by the Martin Advertising website privacy policy.
                </p>
            </section>

            <section>
                <h2>Links and Third-Party Content</h2>
                <p>
                    This and any other websites hosted by Martin Advertising may contain links to other websites and resources and third-party content, for example, message board posts or blog comments. Martin Advertising is not responsible for such content, or that of any linked websites. You will not remove or alter any third party’s copyright or trademark notices or other identifier, except as allowed by the third-party’s licence of that content.
                </p>
                <p>
                    Should you reasonably believe that any third-party content you access through our websites is in breach of any law, regulation or third party’s rights, you should notify Martin Advertising in writing at the address below. In doing so, please:
                </p>
                <ul>
                    <li>Identify the material which you believe to be infringing.</li>
                    <li>Identify what you believe this material infringes and why.</li>
                    <li>Provide your name, email address, address, and telephone number.</li>
                    <li>Confirm that you believe in good faith that this material is infringing a law or third party’s rights and that, to the best of your knowledge, the information you are providing is correct.</li>
                    <li>Identify if you are acting on behalf of the third party whose rights may have been infringed.</li>
                    <li>Provide your physical or electronic signature.</li>
                </ul>
            </section>

            <section>
                <h2>Disclaimer</h2>
                <p>
                    This and any other websites hosted by Martin Advertising and all information, products and services on them are provided on an “as is” basis, without warranty of any kind, either express or implied. Your use of this and any other websites hosted by Martin Advertising is at your own risk. Martin Advertising disclaims all warranties, express or implied, including without limitation, warranties of merchantability and fitness for a particular purpose.
                </p>
                <p>
                    Martin Advertising disclaims liability for any direct, indirect, incidental, special, consequential, exemplary, punitive or other damages, or lost profits, that may result directly or indirectly from the use of this and any other websites hosted by Martin Advertising and any material that is downloaded or obtained through them.
                </p>
                <p>
                    This includes, without limitation, any damage to computer systems, hardware or software, loss of data, or any other performance failures, any errors, bugs, viruses or other defects that result from, or are associated with the use of this and any other websites hosted by Martin Advertising.
                </p>
            </section>
        </div>
    );
};

export default TermsAndConditions;
