import Header from '../../components/Header';
import WhatWeDo from '../../components/WhatWeDo';
import VideoMasthead from '../../components/VideoMasthead';
import AboutUs from '../../components/AboutUs';
import Team from '../../components/Team';
import ClientShowcase from '../../components/ClientShowcase';
import Footer from '../../components/Footer';

import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import '../../App.css';


const HomePage = () => {

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.sectionId) {
      const { sectionId } = location.state;
      const element = document.getElementById(sectionId);
      if (element) {
        const offset = -document.querySelector('.header').offsetHeight;
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset + offset;
        window.scrollTo({ top: elementPosition, behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <div className="App">
      {/* <Header /> */}
      <section id="VideoMasthead">
        <VideoMasthead />
      </section>
      <section id="WhatWeDo">
        <WhatWeDo />
      </section>
      <section id="AboutUs">
        <AboutUs />
      </section>
      <section id="Team">
        <Team />
      </section>
      <section id="Clients">
        <ClientShowcase />
      </section>
      <section id="Contact">
        {/* <Footer /> */}
      </section>
    </div>
  );
}

export default HomePage;
