import Nanci from '../../assets/Nanci.jpg'
import Crawford from '../../assets/Crawford.jpg'
import Eric from '../../assets/Eric.jpg'
import Lindsey from '../../assets/Lindsey.jpg'
import Lee from '../../assets/Lee.jpg'
import Lauren from '../../assets/Lauren.jpg'
import Dylan from '../../assets/Dylan.jpg'
import Emily from '../../assets/Emily.jpg'
import Mary from '../../assets/Mary_Hannah.jpg'
import Brannon from '../../assets/Brannon.jpg'
import Michael from '../../assets/Michael.jpg'
import Cady from '../../assets/Cady.jpg'
import Hunter from '../../assets/Hunter.jpg'

const teamData = [
    {
        name: 'Nanci Sexton',
        photo: Nanci,
        title: 'vice president',
    },
    {
        name: 'Crawford Miller',
        photo: Crawford,
        title: 'VP Managing Director',
    },
    {
        name: 'Eric Holsomback',
        photo: Eric,
        title: 'creative director',

    },
    {
        name: 'Lindsey Vansant',
        photo: Lindsey,
        title: 'business manager',
    },
    {
        name: 'Lee Panter',
        photo: Lee,
        title: 'software director',
    },
    {
        name: 'Lauren Ashworth',
        photo: Lauren,
        title: 'account executive',
    },
    {
        name: 'Dylan Haynes',
        photo: Dylan,
        title: 'senior account executive',
    },
    {
        name: 'Emily Redmon',
        photo: Emily,
        title: 'client business manager',
    },
    {
        name: 'Mary-Hannah Jenkins',
        photo: Mary,
        title: 'digital traffic manager',
    },
    {
        name: 'Michael Hurst',
        photo: Michael,
        title: 'digital traffic manager',
    },
    {
        name: 'Brannon Hall',
        photo: Brannon,
        title: 'senior graphic artist',
    },
    {
        name: 'Cady Metzger',
        photo: Cady,
        title: 'assistant account executive',
    },
    {
        name: 'Hunter Martin',
        photo: Hunter,
        title: 'president'
    }
];

export default teamData;
