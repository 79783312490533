import React from 'react';
import './VideoMasthead.css';

const videoUrl = `https://pub-a6410c3681b541449845fc3f20fbc373.r2.dev/lyricLoop.mp4`;

const VideoMasthead = () => {
  return (
    <div className="video-masthead">
      <video src={videoUrl} className="video-background" autoPlay loop muted preload="auto">
      </video>
      <div className="video-overlay">
        <p>Giving our clients an <span className="highlight">unfair advantage</span> since 1977.</p>
      </div>
    </div>
  );
};

export default VideoMasthead;
