import React from 'react';
import './ClientShowcase.css';
import ClientData from './clientsData'; // Assuming you have an array of client data

const ClientShowcase = () => {
  return (
    <section className="clients">
    <h2 className="clients-title">our current and previous clients:</h2>
    <div className="clients-container">
      {ClientData.map((Client, index) => (
        <div key={index} className="client-logo">
          <img src={Client.logo} alt={Client.name} />
        </div>
      ))}
    </div>
  </section>
  );
};

export default ClientShowcase;
