import React from 'react';
import './CareerPage.css'; // Assuming we're using a separate CSS file for this page

const CareerPage = () => {
  return (
    <div className="career-page">
      {/* Top Section: Header and Apply Button */}
      <div className="top-section">
        <div className="left-content">
          <h1>We are Always Looking for Creative Thinkers and Doers.</h1>
        </div>
        <div className="right-content">
          <button className="apply-button" onClick={() => (window.location.href = 'mailto:cmiller@martinadvertising.com?subject=Job Application')}
          >
            <span>Click to</span> <br /> Apply!
          </button>
        </div>
      </div>

      {/* Middle Section: Paragraph */}
      <div className="middle-section">
        <p>
          Martin is for passionate, driven individuals who are eager to
          contribute to high-quality work that stands out in the advertising
          landscape. Explore our current job openings and discover how you can
          be part of a team that values creativity, trust, and a commitment to
          excellence.
        </p>
      </div>

      {/* Bottom Section: Job Openings */}
      <div className="openings-section">
        <h2>Openings</h2>
        <div className="job-openings">
          <a href="/pdfs/SeniorMediaPlanner-Buyer.pdf" className="job-button" target="_blank" rel="noopener noreferrer">Senior Media Planner-Buyer
          </a>
          <a href="/pdfs/AutomotiveAccountManager.pdf" className="job-button" target="_blank" rel="noopener noreferrer">
          Auto Account Manager
          </a>
          <a href="/pdfs/DigitalTrafficAssistant.pdf" className="job-button" target="_blank" rel="noopener noreferrer">
            Digital Traffic Assistant
          </a>
      
        </div>
      </div>
    </div>
  );
};

export default CareerPage;
