import React from 'react';
import './AboutUs.css';
import imageFile from '../../assets/martinBuilding.JPG'; // Ensure the path is correct

const AboutUs = () => {
  return (
    <div className="about-us">
      <img src={imageFile} alt="About Us" className="about-us-image" />
      <h2>about Martin Advertising.</h2>
      <p>
        Our team of creatives, developers, and strategists give businesses of all types and sizes an unfair marketing advantage through collaboration and the creation of unique and thoughtful solutions with lasting value.
        <br /><br />
        Since 1977, Martin Advertising has been delivering results based on a relational approach. From our founding in Birmingham, AL, the desire to see our clients succeed was as present as our desire to see ourselves succeed. Our clients' success is our success. We grow as our clients grow. And we invest fully in the process of accomplishing our clients' goals. Delivering Results together. As one Team.
        <br /><br />
        As a full-service marketing agency complete with onsite studio and production facility, we run at retail speed to get your message to market and customers to your door. From creative development, media planning and buying, direct marketing, website development, and much more, Martin has everything you need to deliver RESULTS.
      </p>
    </div>
  );
};

export default AboutUs;
