import Cadillac from '../../assets/CADILLAC_LOCKUP_POS_RGB.png'
import ACE from '../../assets/Ace_Hardware_Logo.svg.png'
import AlabamaPower from '../../assets/Alabama_Power_Company_Logo.jpg'
import Walmart from '../../assets/Walmart-Logo-slogan.png'
import Buick from '../../assets/Buick_RGB_horz_sm_pos.png'
import UnitedWay from '../../assets/United_Way_Worldwide_logo.svg.png'
import WalterEnergy from '../../assets/walter_energy.jpg'
import FifthThirdBank from '../../assets/fifth-thirdbank-2c-HEX.jpg'
import GMC from '../../assets/GMC_RGB_POS_BROADCAST.png'
import Chevy from '../../assets/Chevrolet_Horizontal_MD_5in_RGB_DEEP_GRAY.png'
import NashvilleZoo from '../../assets/Nashville-Zoo-Logo.png'
import Allstate from '../../assets/Allstate.jpg'

const teamData = [
    {
        name: 'Cadillac',
        logo: Cadillac,
    },
    {
        name: 'ACE Hardware',
        logo: ACE,
    },
    {
        name: 'Alabama Power',
        logo: AlabamaPower,
    },
    {
        name: 'Walmart',
        logo: Walmart,
    },
    {
        name: 'Buick',
        logo: Buick,
    },
    {
        name: 'United Way',
        logo: UnitedWay,
    },
    {
        name: 'Walter Energy',
        logo: WalterEnergy,
    },
    {
        name: 'Fifth Third Bank',
        logo: FifthThirdBank,
    },
    {
        name: 'GMC',
        logo: GMC,
    },
    {
        name: 'Chevy',
        logo: Chevy,
    }, {
        name: 'Nashville Zoo',
        logo: NashvilleZoo,
    },
    {
        name: 'Allstate',
        logo: Allstate,
    }
];

export default teamData;
