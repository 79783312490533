import React, { useState, useRef } from 'react';
import './WhatWeDo.css';

const sectionsData = [
  { title: 'Strategy', content: 'We excel in crafting comprehensive strategies, from Brand and Creative to Messaging and Marketing, ensuring your business thrives in a competitive market.' },
  { title: 'Communication', content: 'We offer top-notch Copywriting and Business Communications, alongside effective Employee Communications and Annual Reports. If you need engaging Presentations, Direct Mail, Integrated Campaigns, or Outdoor Advertising, we have you covered.' },
  { title: 'Retail', content: 'We create eye-catching Package Design, Retail Promotions, and Interior/Exterior Signage. We ensure your brand stands out and engages customers at every touchpoint within the retail environment.' },
  { title: 'Branding', content: 'We provide Brand Assessment, Logo Design, and Graphic Design to establish your visual identity. We also offer Development, Messaging and Stewardship to ensure consistent and impactful brand communication.' },
  { title: 'Design', content: 'We offer clean and intuitive design for print and digital media along with user interface and user experience solutions for visually appealing but also intuitive interfaces.' },
  { title: 'Digital', content: 'We cover all your online needs, from Website Design and Optimization to Digital Advertising and Google Ads. We also offer Social Media Marketing, E-commerce, and targeted Email Marketing to boost your digital presence and drive results.' },
  { title: 'Environmental', content: 'We create engaging Corporate Interiors, Trade Exhibit Designs, and Portable Graphics. We transform spaces to leave a lasting impression.' },
  { title: 'Photo + Video', content: 'We create compelling Commercials, Web Videos, and Testimonials, alongside offering Photography and Headshots for any advertising or in-house need.' },
];

const WhatWeDo = () => {
  const [selectedSection, setSelectedSection] = useState(0);
  const scrollbarRef = useRef(null);
  const dragging = useRef(false);
  const categoriesScrollRef = useRef(null);

  const handleCategoryHover = (index) => {
    setSelectedSection(index);
  };

  const handleMouseDown = (directionType) => (event) => {
    event.preventDefault();
    dragging.current = true;
    window.addEventListener('mousemove', handleDrag(directionType));
    window.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseUp = () => {
    dragging.current = false;
    window.removeEventListener('mousemove', handleDrag('horizontal'));
    window.removeEventListener('mousemove', handleDrag('vertical'));
    window.removeEventListener('mouseup', handleMouseUp);
  };

  const handleDrag = (directionType) => (event) => {
    if (dragging.current && scrollbarRef.current) {
      const rect = scrollbarRef.current.getBoundingClientRect();
      let newPosition, sectionSize, newIndex;

      if (directionType === 'horizontal') {
        newPosition = event.clientX - rect.left;
        sectionSize = rect.width / sectionsData.length;
      } else {
        newPosition = event.clientY - rect.top;
        sectionSize = rect.height / sectionsData.length;
      }

      newIndex = Math.min(
        Math.max(Math.round(newPosition / sectionSize), 0),
        sectionsData.length - 1
      );
      setSelectedSection(newIndex);
    }
  };

  return (
    <div className="what-we-do">
      <h2>what we do.</h2>
      {/* Desktop View */}
      <div className="desktop-view">
        <div className="categories">
          {sectionsData.map((section, index) => (
            <div
              key={index}
              className={`category ${selectedSection === index ? 'active' : ''}`}
              onMouseEnter={() => handleCategoryHover(index)} // Trigger on hover
            >
              {section.title}
            </div>
          ))}
        </div>
        <div className="scrollbar" ref={scrollbarRef}>
          <div
            className="scroll-indicator"
            style={{
              transform: `translateY(${selectedSection * ((400 - 20) / (sectionsData.length - 1))}px)`, // Match scroll indicator height
            }}
            onMouseDown={handleMouseDown('vertical')}
          ></div>
        </div>
        <div className="content">
          <p>{sectionsData[selectedSection].content}</p>
        </div>
      </div>

      {/* Mobile View */}
      <div className="mobile-view">
        <div
          className="categories-scroll"
          ref={categoriesScrollRef}
          onMouseDown={handleMouseDown('horizontal')}
        >
          {sectionsData.map((section, index) => (
            <div
              key={index}
              className={`category ${selectedSection === index ? 'activeMobile' : ''}`}
              onMouseEnter={() => handleCategoryHover(index)} // Trigger on hover
            >
              {section.title}
            </div>
          ))}
        </div>
        <div className='mobileContentLine'></div>
        <div className="content">
          <p>{sectionsData[selectedSection].content}</p>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
