import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-container">
            <h1>Privacy Notice</h1>
            <p>
                {/* Insert the first paragraph here */}
                Martin Advertising LLC (“MA”, “we,” “us,” or “our”) is one of the nation’s largest automotive
                retail advertising and media spot buying agencies in the country. We work hard, innovate, and
                deliver the best solutions to drive business for our clients. This Privacy Notice sets out how we
                collect, use, share, and protect any information we collect through this website (“Website”). This
                Privacy Notice also explains the measures we take to safeguard your information and describes how
                you may contact us regarding our privacy practices.
            </p>

            <h2>Introduction</h2>
            <p>
                {/* Insert the introduction text here */}
                Any data collected through this Website, including personal data (as defined under applicable data
                protection laws, referred to as “Personal Data”), will only be used for the purposes set out in
                this Privacy Notice. Notwithstanding, we may use information that does not identify you (including
                information that has been aggregated or de-identified) for any purpose except as prohibited by
                applicable law.
            </p>

            <p>
                Please read this Privacy Notice thoroughly. If you disagree with the way personal information will
                be used, please do not use this Website. For our Canadian users, your use of the Website
                constitutes your implied consent for us to process your Personal Data for the above-noted purposes.
            </p>
            <p>
                We may include links to other websites, including social media platforms. This Privacy Notice only
                covers this Website and does not apply to the data practices of other websites. We encourage you to
                read the privacy notices available on other websites you visit.
            </p>

            <h2>
                What Personal Data and Sensitive Personal Information do we collect and process through the
                Website?
            </h2>
            <p>The following table describes the categories of Personal Data and Sensitive Information we collect.</p>

            {/* Table for Personal Data */}
            <table>
                <thead>
                    <tr>
                        <th>Category of Personal Data</th>
                        <th>Examples</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Contact Information and Identifiers</td>
                        <td>First name, last name, e-mail address, telephone number; IP address</td>
                    </tr>
                    <tr>
                        <td>
                            Internet or other electronic network activity information (Technical data)
                        </td>
                        <td>
                            Browsing history; search history; online interests, such as information about categories of
                            consumer interests derived from online usage; and information on a consumer's interaction
                            with the website
                        </td>
                    </tr>
                    <tr>
                        <td>General Geolocation data</td>
                        <td>Non-precise location information from your device or IP address</td>
                    </tr>
                    <tr>
                        <td>Your verbatim communications to us</td>
                        <td>
                            Information that you directly send us by using the Contact Us form on the website
                        </td>
                    </tr>
                    <tr>
                        <td>Inferences</td>
                        <td>
                            Information drawn from any of the information identified in this section to create an
                            attribute about your preferences, characteristics, or behavior
                        </td>
                    </tr>
                </tbody>
            </table>

            <h2>
                Categories of the sources of Personal Data and Sensitive Personal Data we may collect:
            </h2>
            <p>
                We may collect the Personal Data outlined above from the following categories of sources:
            </p>
            <ul>
                <li>
                    Directly from you, for example, when you send us your questions or comments using the query form
                    or make use of your privacy rights.
                </li>
                <li>
                    Indirectly from you by using automated technologies, such as cookies, pixels, third party tags,
                    scripts, log files and other means to automatically collect information about the use of our
                    Website Further information about our use of cookies can be found in this Privacy Notice.
                </li>
            </ul>

            <h2>Our Purposes for collecting and using your Personal Data</h2>
            <ul>
                <li>To read and respond to your queries to us, sent using our online query form.</li>
                <li>When you contact us through the data access request form</li>
                <li>To contact you where you have indicated you wish to receive news from us.</li>
                <li>Operating our Website, for example, operating, analyzing, improving, and securing our Website.</li>
                <li>
                    Other internal purposes, such as: internal research, internal operations, auditing, detecting
                    security incidents, debugging, short-term and transient use, quality control, and legal
                    compliance.
                </li>
                <li>
                    Legal purposes, such as: (a) comply with legal process or a regulatory investigation (e.g.
                    regulatory authorities’ investigation, subpoena, or court order); (b) enforce our Terms of
                    Service, this Privacy Notice, or other contracts with you, including investigation of potential
                    violations thereof; (c) respond to claims that any content violates the rights of other parties;
                    and/or (d) protect the rights, property or personal safety of us, our platform, our customers,
                    our agents and affiliates, its users and/or the public. Such disclosure may be for a business
                    purpose, but may also be considered a “sale” under certain state privacy laws.
                </li>
                <li>
                    In the event of a corporate transaction including for example a merger, investment, acquisition,
                    reorganization, consolidation, bankruptcy, liquidation, or sale of some or all of our assets, or
                    for purposes of due diligence connected with any such transaction.
                </li>
                <li>
                    We may aggregate, de-identify and/or anonymize any information collected so that such information
                    can no longer be linked to you or your device (“Aggregate/De-Identified Information”). We may use
                    Aggregate/De-Identified Information for any purpose, including without limitation for research
                    and marketing purposes, and may also share such data with any other party, including advertisers,
                    promotional partners, and sponsors, in our discretion, unless otherwise prohibited by applicable
                    law
                </li>
                <li>
                    We use analytics services, such as Google Analytics, to help us understand how users access and
                    use the Website.
                </li>
            </ul>

            <h2>Purpose of data collection</h2>
            <p>
                The following table provides information about our purposes for collecting your Personal Data and
                Sensitive Personal Data:
            </p>

            <h2>With whom do we Share or Sell Personal Data or Sensitive Personal Data?</h2>
            <p>
                We may disclose your Personal Data collected and processed through this Website for various
                purposes related to our business. The disclosure may be to our clients, partners, or vendors.
            </p>
            <p>
                Sharing Personal Data under the California Privacy Rights Act means to disclose your Personal Data
                to a third party for Interest-based Advertising.
            </p>
            <p>
                Selling Personal Data means to disclose your Personal Data to a third party for an exchanged value
                and a purpose other than one of the listed business purposes in this Privacy Notice.
            </p>
            <p>
                The table below shows with whom we may Share or Sell your Personal Data, and whether we believe we
                have “sold” or “shared” a particular category of information in the prior 12 months.
            </p>
            
            <table>
                <thead>
                    <tr>
                        <th>Category of Personal Data</th>
                        <th>Categories of Parties With Whom We Sold or Shared Your Data</th>
                        <th>“Sold” in the Last 12 Months</th>
                        <th>“Shared” in the last 12 months</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Contact Information and Identifiers</td>
                        <td>Affiliates, Analytics Partners, Service Providers</td>
                        <td>No</td>
                        <td>No</td>
                    </tr>
                    <tr>
                        <td>Internet or other electronic network activity information</td>
                        <td>Analytics Partners and Service Providers</td>
                        <td>No</td>
                        <td>No</td>
                    </tr>
                    <tr>
                        <td>Geolocation data</td>
                        <td>Analytics Partners and Service Providers</td>
                        <td>No</td>
                        <td>No</td>
                    </tr>
                    <tr>
                        <td>Your verbatim communications to us</td>
                        <td>Affiliates, Service Providers</td>
                        <td>No</td>
                        <td>No</td>
                    </tr>
                    <tr>
                        <td>Inferences</td>
                        <td>Analytics Partners, Service Providers</td>
                        <td>No</td>
                        <td>No</td>
                    </tr>
                </tbody>
            </table>

            <h2>
                With whom do we disclose Personal Data or Sensitive Personal Data for a Business Purpose?
            </h2>
            <p>
                We may disclose your Personal Data or Sensitive Personal Data to our service providers that assist
                us with our business purposes. Our service providers are to only process your Personal Data in
                accordance with our instructions and only for the purpose listed below.
            </p>
            <p>
                The table below shows with whom we disclose your Personal Data for the specific business
                purpose(s).
            </p>
           
            <table>
  <thead>
    <tr>
      <th>Category of Personal Data</th>
      <th>Categories of Parties With Whom We Disclosed Your Data for a Business Purpose</th>
      <th>Business Purpose for the Disclosure</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Contact Information and Identifiers</td>
      <td>Affiliates, Analytics Partners, Service Providers</td>
      <td>
        Helping to ensure security and integrity to the extent the use of the consumer's personal information is reasonably necessary and proportionate for these purposes; undertaking activities to verify or maintain the quality or safety of a service or device that is owned, manufactured, manufactured for, or controlled by the Controller, and to improve, upgrade, or enhance the service or device that is owned, manufactured, manufactured for, or controlled by the Controller
      </td>
    </tr>
    <tr>
      <td>Internet or other electronic network activity information</td>
      <td>Affiliates, Analytics Partners, Service Providers</td>
      <td>
        Helping to ensure security and integrity to the extent the use of the consumer's personal information is reasonably necessary and proportionate for these purposes; undertaking activities to verify or maintain the quality or safety of a service or device that is owned, manufactured, manufactured for, or controlled by the Controller, and to improve, upgrade, or enhance the service or device that is owned, manufactured, manufactured for, or controlled by the Controller
      </td>
    </tr>
    <tr>
      <td>Geolocation data</td>
      <td>Analytics Partners and Service Providers</td>
      <td>
        Helping to ensure security and integrity to the extent the use of the consumer's personal information is reasonably necessary and proportionate for these purposes; undertaking activities to verify or maintain the quality or safety of a service or device that is owned, manufactured, manufactured for, or controlled by the Controller, and to improve, upgrade, or enhance the service or device that is owned, manufactured, manufactured for, or controlled by the Controller
      </td>
    </tr>
    <tr>
      <td>Your verbatim communications to us</td>
      <td>Affiliates, Analytics Partners, Service Providers</td>
      <td>
        Depending on your request, we will rely on our legitimate business interest or the performance of pre-contractual measures to respond to individuals’ queries and helping to ensure security and integrity to the extent the use of the consumer's personal information is reasonably necessary and proportionate for these purposes.
      </td>
    </tr>
    <tr>
      <td>Inferences</td>
      <td>Analytics Partners and Service Providers</td>
      <td>
        Helping to ensure security and integrity to the extent the use of the consumer's personal information is reasonably necessary and proportionate for these purposes; undertaking activities to verify or maintain the quality or safety of a service or device that is owned, manufactured, manufactured for, or controlled by the Controller, and to improve, upgrade, or enhance the service or device that is owned, manufactured, manufactured for, or controlled by the Controller
      </td>
    </tr>
  </tbody>
</table>

            <h2>Automated Decision Making and Profiling</h2>
            <p>
                When processing your Personal Data, we do not use processes that involve automated decision making
                or profiling.
            </p>

            <h2>How long do we keep your information?</h2>
            <p>
                We keep Personal Data for as long as is necessary for the purposes described in this Privacy
                Notice, complying with legal and regulatory obligations, protecting our or other’s interests, and
                as otherwise permitted or required by law. When Personal Data is no longer necessary for or
                compatible with these purposes, it is removed from our systems in accordance with our internal
                retention policies. The criteria used to determine our retention periods include:
            </p>
            <ul>
                <li>
                    The length of time we have an ongoing relationship with you and provide the Website or our
                    services to you;
                </li>
                <li>
                    Whether our processing of the Personal Data is consistent with what an average consumer would
                    expect when the Personal Data was collected;
                </li>
                <li>
                    Whether the Personal Data is processed for any other disclosed purpose(s) compatible with what is
                    reasonably expected by the average consumer;
                </li>
                <li>
                    Whether the Personal Data is relevant and useful to the provision of our services and our further
                    processing is permitted by law;
                </li>
                <li>Whether the Personal Data has been de-identified, anonymized, and/or aggregated; and</li>
                <li>Whether there is a legal obligation to which we are subject.</li>
            </ul>

            <h2>Your rights and choices regarding your Personal Data</h2>
            <p>
                Please use this link if you want to make use of any of your below-mentioned legal rights.
            </p>

            <h3>1. Where you are a US resident</h3>
            <h4>Your Rights and Choices</h4>
            <p>
                Residents of certain US states, such as California, Virginia, Colorado, Connecticut, and Utah, have
                specific rights regarding their Personal Data. This section describes how to exercise those rights
                and our process for handling those requests. (To the extent permitted by applicable law, we may
                charge a reasonable fee to comply with your request.)
            </p>

            <h4>1.1 Right to request access to your Personal Data</h4>
            <p>
                You may request access to your Personal Data that we collect, use, disclose, or sell. In
                particular, you may request:
            </p>
            <ul>
                <li>the specific pieces of Personal Data that we process about you</li>
                <li>the categories of Personal Data we have collected about you;</li>
                <li>the categories of sources from which the Personal Data was collected;</li>
                <li>
                    the categories of Personal Data about you we disclosed for a business purpose or sold or shared;
                </li>
                <li>
                    the categories of third parties to whom the Personal Data was disclosed for a business purpose or
                    sold or shared; and
                </li>
                <li>the business or commercial purpose for processing the Personal Data.</li>
            </ul>
            <p>
                When exercising the right to access Personal Data, you have the right to obtain materials in a
                portable and, to the extent technically feasible, readily usable format that allows you to transmit
                the data to another entity without hindrance.
            </p>
            <p>
                When we receive and verify your request to access your Personal Data, we will make best efforts to
                fulfill your request unless an exception applies. We will not disclose your government
                identification numbers, financial account numbers, health insurance or medical identification
                numbers, account passwords, security questions and answer, or unique biometric data; however, to
                the extent we have this information, we will inform you that we have collected this information.
                You are permitted 2 access reports every 12 months.
            </p>


            <h4>1.2 Right to request deletion of your Personal Data</h4>
            <p>
                You may also request that we delete any Personal Data that we obtained about you. However, we
                may retain Personal Data for certain important purposes, as set out by applicable law.
            </p>
            <p>
                When we receive and verify your request to delete your Personal Data, we will proceed to delete
                the data unless an exception applies. We will retain a record of your deletion request in order
                to keep your Personal Data deleted, unless and until you provide consent for the processing of
                your Personal Data post your deletion request.
            </p>

            <h4>1.3 Right to correct your Personal Data</h4>
            <p>
                If you believe we hold inaccurate personal information about you, you may request that we
                correct that information. We may ask for documentation showing that the information you submit
                is accurate and the Personal Data we hold is inaccurate. Once we receive your request, we will
                verify your identity and taking into account the type of personal information and the purposes
                of our holding that personal information we will make best efforts to complete your request. We
                may deny your request if we determine that the contested Personal Data is more likely than not
                accurate. Additionally, we may choose to delete your Personal Data in response to your request
                to correct your Personal Data if the deletion of your Personal Data would not negatively impact
                you.
            </p>

            <h4>1.4 Right to limit use of Sensitive Personal Data</h4>
            <p>
                You may request that we limit the use and disclosure of your Sensitive Personal Data. However,
                we may deny your request in part or in full under uses authorized by applicable law.
            </p>

            <h4>1.5 Right to nondiscrimination</h4>
            <p>
                We will not deny, charge different prices for, or provide a different level of quality of goods
                or services if you choose to exercise your privacy rights.
            </p>

            <h4>1.6 Right to Opt-out of the sale of your Personal Data</h4>
            <p>
                We do not sell any Personal Data as the term ‘sell’ is used in US state privacy laws like the
                California Consumer Privacy Act and the Virginia Consumer Data Protection Act.
            </p>

            <h4>1.7 Universal Opt-Out Mechanisms</h4>
            <p>
                Our Website recognizes certain global opt-out of sale and share preference signals like the
                Global Privacy Control and the IAB CCPA privacy string/MSPA privacy string. This means that if
                you enact your choice to opt-out or opt-in through these signals, we will honor your choice
                upon receiving the signal related to your visit on our Website. We will apply your preference
                to the Personal Data we collected from our Website. If you have previously provided affirmative
                consent for the sale or share of your Personal Data, and your opt-out choice via the signal
                conflicts with such consent, we will ignore the opt-out choice via the signal.
            </p>

            <h4>1.8 Shine the Light</h4>
            <p>
                Customers who are residents of California may request (i) a list of the categories of Personal
                Data disclosed by us to third parties during the immediately preceding calendar year for those
                third parties’ own direct marketing purposes; and (ii) a list of the categories of third
                parties to whom we disclosed such information. To exercise a request, please write us at the
                email or postal address set out in the “Further Information” section below and specify that you
                are making a “California Shine the Light Request.”
            </p>

            <h4>1.9 How to exercise your US state privacy rights</h4>
            <p>
                US residents may exercise their applicable privacy rights by submitting your request{' '}
                <a href="https://publicisresources-privacy.my.onetrust.com/webform/a57c3297-68d3-4bc0-8843-262957a92400/b1136b07-2ab3-4a30-8ee0-87ac98e16ab0">here</a> or by contacting us at 1-205-930-9200.
            </p>
            <p>
                For security purposes (and as required under US state laws), we will verify your identity—in
                part by requesting certain information from you—when you make a request to access, correct, or
                delete your Personal Data. The verification process will utilize an email verification process,
                a telephone number verification process, and, if applicable, a request for any assigned ID
                number previously communicated to you.
            </p>
            <p>
                If we are unable to complete your request fully for any reason, we will provide you additional
                information about the reasons why we could not comply with your request.
            </p>
            <p>
                You may also designate an agent to make requests to exercise your rights under certain US state
                laws. We will take steps both to verify your identity and to verify that your agent has been
                authorized to make a request on your behalf by requesting your agent to provide us with a
                signed written authorization or a copy of a power of attorney executed by you.
            </p>

            <h3>2. Appeal</h3>
            <p>
                It is your right to appeal a decision made concerning the exercise of your data privacy rights. You
                may appeal this decision within 60 days of receiving your response from us. To submit your appeal
                visit our  <a href="https://publicisresources-privacy.my.onetrust.com/webform/a57c3297-68d3-4bc0-8843-262957a92400/b1136b07-2ab3-4a30-8ee0-87ac98e16ab0">portal</a>.
            </p>

            <h3>3. Where you are a Nevada Resident</h3>
            <p>
                Nevada law (NRS 603A.340) requires each business to establish a designated request address where
                Nevada consumers may submit requests directing the business not to sell certain kinds of Personal
                Data that the business has collected or will collect about the consumer. A sale under Nevada law is
                the exchange of Personal Data for monetary consideration by the business to a third party for the
                third party to license or sell the Personal Data to other third parties. If you are a Nevada
                consumer and wish to submit a request relating to our compliance with Nevada law, please contact us
                at privacy@martinadvertising.com.
            </p>

            <h3>4. Your general rights regarding data collection</h3>
            <h4>4.1 Do Not Track:</h4>
            <p>
                Your browser settings may allow you to automatically transmit a “Do Not Track” signal to online
                services you visit. Note, however, there is no industry consensus as to what site and app operators
                should do with regard to these signals. Accordingly, unless and until the law is interpreted to
                require us to do so, we do not monitor or take action with respect to “Do Not Track” signals. For
                more information on “Do Not Track,” visit{' '}
                <a href="https://www.allaboutdnt.com">https://www.allaboutdnt.com</a>.
            </p>

            <h4>4.2 Analytics:</h4>
            <p>
                We may use our own technology or third-party technology to track and analyze usage information to
                provide enhanced interactions and more relevant communications, and to track the performance of our
                advertisements.
            </p>
            <p>
                For example, we use Google Analytics (“Google Analytics”), a web analytics service provided by
                Google, Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. You can learn about Google’s
                privacy practices by going to  <a href="www.google.com/policies/privacy/‌partners/">www.google.com/policies/privacy/‌partners/</a>.
            </p>
            <p>
                Google Analytics uses cookies to help us analyze how our websites are used, including the number of
                visitors, the websites from which visitors have navigated to our websites, and the pages on our
                websites to which visitors navigate. This information is used by us to improve our websites. We use
                Google Analytics with restrictions on how Google can process our data enabled. For information on
                Google’s Restricted Data Processing go to <a href="https://privacy.google.com/businesses/rdp/">https://privacy.google.com/businesses/rdp/</a>.
            </p>

            <h4>4.3 E-mails:</h4>
            <p>
                You can opt-out of receiving promotional emails from us at any time by following the instructions
                as provided in emails to click on the unsubscribe link, or emailing us at the email address set out
                in the “Further Information” section below with the word unsubscribe in the subject field of the
                email. Please note that you cannot opt-out of non-promotional emails, such as those about
                transactions, servicing, or our ongoing business relations.
            </p>

            <h2>Children</h2>
            <p>
                The Website is intended for general audiences and is not directed at children. We do not knowingly
                collect Personal Data (as defined by the U.S. Children’s Privacy Protection Act, or “COPPA”) from
                children. If you are a parent or guardian and believe we have collected Personal Data in violation
                of COPPA, contact us at privacy@martinadvertising.com. We will remove the Personal Data in
                accordance with COPPA. <strong>We do not knowingly “sell,” as that term is defined under the US state
                privacy laws, like CCPA or VCDPA, the Personal Data of minors under 16 years old without legally
                required affirmative authorization.</strong> 
            </p>

            <h2>Data Security</h2>
            <p>
                We use a variety of methods, such as firewalls, intrusion detection software and manual security
                procedures, designed to secure your data against loss or damage and to help protect the accuracy
                and security of information and to prevent unauthorized access or improper use. Nevertheless,
                transmission via the internet is not completely secure and we cannot guarantee the security of
                information about you. If you think that the Website or any Personal Data is not secure or that
                there has been unauthorized access to the Website or your Personal Data, please contact
                privacy@martinadvertising.com immediately.
            </p>

            <h2>
                Use of cookies, other tracking technology, social media and social media plug-ins
            </h2>
            <p>
                Cookies are small pieces of text or code sent to your device when you visit the Website. Cookies
                are used to optimize the viewing experience, count visitors to a webpage, troubleshoot any
                problems, keep websites secure, and better serve content. The following cookies are used on the
                Website:
            </p>
            <ul>
                <li>
                    <strong>Strictly Necessary cookies</strong> are required for the website to function and cannot
                    be switched off in our systems. Some of these cookies are only set in response to requests made
                    by you, such as setting your preferences, logging in or filling out forms. They may also be set
                    for certain security purposes and network management. You can manage these types of cookies using
                    your browser settings, but it may affect your ability to use our website.
                </li>
                <li>
                    <strong>Performance cookies</strong> allow us to engage in certain activities, such as counting
                    visits and identifying traffic sources that assist us in measuring the performance of our
                    website. They help us to know which pages are the most and least popular and see how visitors
                    move around the website. If you do not allow these cookies we will not know when you have visited
                    our website and will not be able to monitor our website’s performance.
                </li>
            </ul>
            <p>
                Overall, cookies help us provide you with a better Website, by enabling us to monitor which pages
                you find useful and which you do not. You can choose to accept or decline cookies. Most web
                browsers automatically accept cookies, but you can usually modify your browser setting to decline
                cookies if you prefer. This may prevent you from taking full advantage of the Website.
            </p>
            <p>
                If you follow us on social media (for example, through our accounts on LinkedIn, Facebook, or
                Instagram), we will also collect Personal Data about you in order to understand our followers
                better and understand the public response to our products and services. We may use this information
                to engage in social listening to identify and assess what is being said about us publicly to
                understand industry trends and market sentiment. Any information you provide to us when you engage
                with our content (such as through our brand page or via Facebook Messenger) is treated in
                accordance with this Privacy Notice. Also, if you publicly reference us or our Website on social
                media (such as by using a hashtag associated with us in a tweet or post), we may use your reference
                on or in connection with our Website.
            </p>
            <p>
                We provide social media plug-ins on the Website to allow you to easily share content from our
                Website through social media, and in doing so, we may receive your Personal Data from the social
                media platform that you have authorized to share with us. When you share content using these
                buttons, a new page will pop up from the relevant social media platform. If you’re already logged
                into your account on that social media platform, then you will probably be automatically logged
                into this pop-up page, so that you don’t have to log in again. If, however, you are not logged in
                (or if you are not registered with the social media platform), the page will ask you for your
                information.
            </p>
            <p>
                <strong>We have no control over how social media platforms use your Personal Data and they may
                independently collect information about you when you leave our Website.</strong> The information collected
                and stored by those parties remains subject to their own policies and practices, including what
                information they share with us, your rights and choices on their services and devices, and whether
                they store information in the U.S. or elsewhere. We encourage you to read the privacy notices on
                the various social media platforms you use.
            </p>
            <p>Find out more about how these social media platforms use your Personal Data:</p>
            <ul>
                <li><a href='https://en-gb.facebook.com/privacy/explanation'>Facebook</a></li>
                <li><a href='https://policies.google.com/privacy?hl=en'>YouTube (Google’s general privacy policy)</a></li>
                <li><a href='https://help.instagram.com/519522125107875'>Instagram</a></li>
                <li><a href='https://www.linkedin.com/legal/privacy-policy?trk=homepage-basic_footer-privacy-policy'>LinkedIn</a></li>
            </ul>

            <h2>Financial Incentive</h2>
            <p>
                We do not offer any financial incentives related to our collection of your Personal Data.
            </p>

            <h2>Notification of changes</h2>
            <p>
                Any changes to this Privacy Notice will be promptly communicated on this page and you should check
                back to see whether there are any changes. Continued use of the Website after a change in the
                Privacy Notice indicates your acknowledgment and acceptance of the use of Personal Data in
                accordance with the amended Privacy Notice.
            </p>
            <p>If you wish to save a copy of this text, you can click <a href='https://lion.box.com/shared/static/g8263ionawgz9v3tod5uiua2qtnclmtx.pdf'>here</a> to download a PDF.</p>

            <h2>Further Information</h2>
            <p>
                This Privacy Notice has been designed to be accessible to people with disabilities. If you
                experience any difficulties accessing the information here, please contact us at
                privacy@martinadvertising.com.
            </p>
            <p>
                If you consider that we are not complying with this Privacy Notice, if you have any questions in
                relation to this Privacy Notice, or have questions about your rights and choices, please contact
                privacy@martinadvertising.com.
            </p>
            <p>
                If you have any questions about our data practices or you wish to exercise your rights or know
                about the contractual protections in place, please contact us at privacy@martinadvertising.com.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
